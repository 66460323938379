<!--
 * @Description: 富文本配置区域
-->
<template>
  <div>
    <config-wrap title='必输配置'>
      <config-item label='属性名'>
        <el-input v-model="content.curComponent.property" placeholder="请输入属性名" size='small' resize="true"></el-input>
      </config-item>
    </config-wrap>
    <config-wrap title='基础配置'>
      <config-item label='标题'>
        <el-input v-model="content.curComponent.label" placeholder="请输入标题" resize="true" size='small'></el-input>
      </config-item>
      <config-item label='占位符'>
        <el-input v-model="content.curComponent.options.placeholder" placeholder="请输入占位符" resize="true"
          size='small'></el-input>
      </config-item>
      <config-item label='默认值'>
        <el-input v-model="content.curComponent.value" placeholder="请输入默认值" resize="true" size='small'></el-input>
      </config-item>
    </config-wrap>
  </div>
</template>

<script>
export default {
  name: "SchemaRichTxtConfig",

  inject: ["content"],
};
</script>
